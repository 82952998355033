import React, { useReducer, useEffect } from 'react';
import { EventEmitterService, EventKey } from '../services/EventEmitterService';
import { SessionService } from '../services/SessionService';
import { awaitWithLoading, billingPeriod, isToday } from '../services/utils';
import StorageService from '../services/StorageService';
import { COVERAGE_TYPES } from '../models/insurance-models/CoverageType';
import coveragesApi from '../api/coverages.api';
import { merge } from 'merge-anything';
import { SessionContextStore } from './SessionContext';
import { UserApi } from '../api/user.api';
import { Action } from '../models/Action';
import {useParams } from 'react-router-dom';

export const OFFERS_HISTORY_KEY = 'offers_ongoing_purchase';

export const OFFERS_ACTIONS = {
  ADD_OPERATOR: 'ADD_OPERATOR',
  DELETE_OPERATOR: 'DELETE_OPERATOR',
  EDIT_OPERATOR: 'EDIT_OPERATOR',
  SET_SPECIAL_PRICE: 'SET_SPECIAL_PRICE',
  SET_INDOOR_COVERAGE: 'SET_INDOOR_COVERAGE',
  REFER_COMMENTS: 'REFER_COMMENTS',
  UPDATE_PLAN_DURATION_PER_STATE: 'UPDATE_PLAN_DURATION_PER_STATE',
  UPDATE_DISABLED_PLANS: 'UPDATE_DISABLED_PLANS',
};

export const PlanEnum = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Year: 'Year'
}

const defaultState = {
  offerId: '',
  monthlyOffers: [],
  annualOffers: [],
  options: {
    aircraft_damage_liability: [
      {
        value: 0,
        label: '$5000',
      },
    ],
    bodily_injury_liability: [
      {
        labels: ['Per Passenger', 'Accident', 'Property'],
        values: [25000, 50000, 25000],
        value: '25/50/25',
      },
      {
        labels: ['Per Passenger', 'Accident', 'Property'],
        values: [50000, 50000, 50000],
        value: '50/50/50',
      },
    ],
    passenger_liability: [
      {
        labels: ['Per Passenger', 'Accident'],
        values: [25000, 50000],
        value: '25/50',
      },
    ],
    bodily_injury_underinsured: [
      {
        labels: ['Per Passenger', 'Accident'],
        values: [25000, 50000],
        value: '25/50',
      },
    ],
    medical_payments: [
      { label: 'No coverage', value: 0 },
      { label: '$1,000', value: 1000 },
      { label: '$5,000', value: 5000 },
    ],
    physical_damage_comprehensive: [
      { label: 'No coverage', value: 0 },
      { label: '$1,000', value: 1000 },
      { label: '$5,000', value: 5000 },
    ],
  },
  offerCart: {
    autoRenew: true,
    period: {
      plan: PlanEnum.Year,
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()),
    },
    additional_insured: '',
  },
  dailyPriceBreakdown: {autoRenew: false},
  weeklyPriceBreakdown: {autoRenew: false},
  monthlyPriceBreakdown: {autoRenew: true},
  yearlyPriceBreakdown: {autoRenew: true},
  upgradePrice: 0,
  referUnderwriterComments: '',
  offerCardErrors: {},
  violations: [],
  pricebreakdown: {autoRenew: true},
  specialPrice: { enabled: 'no', custom_premium: 0, custom_premium_token: '' },
  planDurationPerState: null,
  currentDisabledPlans: [],
  allPlans: ['Day', 'Week', 'Month', 'Year'],
  changeStartingDate: (date) => {
    throw 'Not Implemented Exception';
  },
  loadQuote: (quote) => {
    throw 'Not implemented';
  },
};

const calculateState = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState;
    case 'SET_VIOLATIONS':
      return { ...state, violations: action.data };
    case 'OFFERS_LOADED':
      return {
        ...state,
        monthlyOffers: action.monthlyOffers,
        annualOffers: action.annualOffers,
      };
    case 'OPTIONS_LOADED':
      return { ...state, options: action.data };
    case 'CART_CHANGED':
      return { ...state, offerCart: action.data };
    case 'PRICE_BREAKDOWN_CHANGED':
      return {
        ...state,
        pricebreakdown: action.data,
        offerId: action.data.offerId,
      };
    case 'DAILY_PRICE_BREAKDOWN_CHANGED':
      return {
        ...state,
        dailyPriceBreakdown: action.data
      };
    case 'WEEKLY_PRICE_BREAKDOWN_CHANGED':
      return {
        ...state,
        weeklyPriceBreakdown: action.data
      };
    case 'MONTHLY_PRICE_BREAKDOWN_CHANGED':
      return {
        ...state,
        monthlyPriceBreakdown: action.data
      };
    case 'YEARLY_PRICE_BREAKDOWN_CHANGED':
      return {
        ...state,
        yearlyPriceBreakdown: action.data
      };
    case 'CHANGE_UPGRADE_PRICE':
      return{
        ...state,
        upgradePrice: action.data
      }
    case 'CHANGE_STARTING_DATE':
      if (isToday(action.startingDate)) {
        return { ...state, subscriptionStartTime: new Date() };
      } else if (
        action.startingDate &&
        action.startingDate > new Date() &&
        !isToday(action.startingDate)
      )
        return { ...state, subscriptionStartTime: action.startingDate };
      else return state;
    case 'LOAD_QUOTE':
      let startingDate = new Date();
      if (action.data.start_time) {
        let requestedStartingTime = new Date(action.data.start_time);
        if (requestedStartingTime > new Date() && !isToday(requestedStartingTime))
          startingDate = requestedStartingTime;
      }
      return {
        ...state,
        subscriptionStartTime: startingDate,
        maximumUasInAir: action.data.maximumUasInAir || action.data.maximum_uas_in_air || 1,
        coverageType:
          action.data.coverage_type || action.data.coverageType || COVERAGE_TYPES.COMBINED,
        operators: action.data.operators || action.data.operators || [],
        worldWideCoverage: {
          territorialLimits: action.data.territorial_limits,
          territorialLimitsDescription: action.data.territorial_limits_description,
        },
        personalInjuryCoverage: Number.parseInt(action.data.personal_injury_limit),
        medicalExpense: Number.parseInt(action.data.medical_expense_limit),
        customPremium: Number.parseInt(action.data.custom_premium),
        indoorCoverage: {
          included: action.data.indoor_coverage,
          description: action.data.indoor_coverage_description,
        },
        customPremiumToken: action.data.custom_premium_token,
      };
    case OFFERS_ACTIONS.SET_SPECIAL_PRICE:
      return { ...state, specialPrice: action.data };
    case OFFERS_ACTIONS.ADD_OPERATOR:
      return { ...state, operators: [...state.operators, action.data] };
    case OFFERS_ACTIONS.EDIT_OPERATOR:
      return {
        ...state,
        operators: state.operators.map((item, index) =>
          index === action.data.index ? action.data.operator : item,
        ),
      };
    case OFFERS_ACTIONS.DELETE_OPERATOR:
      return {
        ...state,
        operators: state.operators.filter((item, index) => index != action.data),
      };
    case 'LOAD_PLANS':
      return { ...state, plans: action.data };
    case OFFERS_ACTIONS.REFER_COMMENTS:
      return { ...state, referUnderwriterComments: action.data };
    case OFFERS_ACTIONS.UPDATE_PLAN_DURATION_PER_STATE:
      return { ...state, planDurationPerState: action.data };
    case OFFERS_ACTIONS.UPDATE_DISABLED_PLANS:
      return { ...state, currentDisabledPlans: action.data };
    default:
      return state;
  }
};

const stateToLocalStorage = (state) => {
  let savedStatte = Object.assign({}, state);
  StorageService.setItem(OFFERS_HISTORY_KEY, JSON.stringify(savedStatte));
};

const reducer = (state, action) => {
  console.log('BuyingInsuranceContext state', state, action);

  let newState = calculateState(state, action);
  stateToLocalStorage(newState);

  return newState;
};

export const BuyingInsuranceContextStore = React.createContext(defaultState);
BuyingInsuranceContextStore.displayName = 'BuyingInsuranceContext'

const BuyingInsuranceContext = (props) => {
  const { insuranceId } = useParams()
  const sessionContext = React.useContext(SessionContextStore);

  const [state, dispatch] = useReducer(reducer, defaultState, () => {
    let savedState = {};
    // Initialize from local storage
    if (StorageService.getItem(OFFERS_HISTORY_KEY)) {
      savedState = JSON.parse(StorageService.getItem(OFFERS_HISTORY_KEY));
      savedState.subscriptionStartTime = new Date(savedState.subscriptionStartTime);
      if (savedState.subscriptionStartTime < new Date() || !savedState.subscriptionStartTime) {
        savedState.subscriptionStartTime = new Date();
      }
    }

    return merge(defaultState, savedState);
  });

  const requestOffer = async () => {
    
        if (defaultState == state) return
        let startTime = undefined
        let dateObj = new Date(state.offerCart.period.startDate)
        if(!(!!dateObj && isToday(dateObj))){
          startTime = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 1, 0, 0));
        }

    let offer = Object.assign({}, state.offerCart);
    delete offer.vehicle_requests;
    delete offer.period;
    delete offer.additional_insureds;
    delete offer.additional_insured;
    delete offer.autoRenew;

    //if(Object.keys(offer).length == 0) return

    // offer.aircraft_damage_liability = state.options.aircraft_damage_liability.find(opt => Number(opt.label.slice(1)) == offer.aircraft_damage_liability).value
    if(insuranceId != null){
      var res = await awaitWithLoading(
        coveragesApi.requestOffer({
          insurance_id: insuranceId,
          start_time: startTime,
          additional_insured: state.offerCart.additional_insured,
          coverages: offer,
          auto_renew: state.offerCart.autoRenew,
          insurance_duration: state.offerCart.period.plan,
          vehicle_requests: state.offerCart.vehicle_requests,
          }),
        );
        dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: res.data });
    }
    else{
      state.allPlans.forEach(async (plan) => {
        var res = await requestOfferWithoutInsuranceId(startTime, state.offerCart.additional_insured, offer, state.offerCart.autoRenew,
          plan, state.offerCart.vehicle_requests)
        if (res.ok) {
          switch(plan){
            case PlanEnum.Year:
              dispatch({ type: 'YEARLY_PRICE_BREAKDOWN_CHANGED', data: res.data });
              break
            case PlanEnum.Month:
              dispatch({ type: 'MONTHLY_PRICE_BREAKDOWN_CHANGED', data: res.data });
              break
            case PlanEnum.Week:
              dispatch({ type: 'WEEKLY_PRICE_BREAKDOWN_CHANGED', data: res.data });
              break
            case PlanEnum.Day:
              dispatch({ type: 'DAILY_PRICE_BREAKDOWN_CHANGED', data: res.data });
              break
          }
          if(state.offerCart.period.plan === plan){
            dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: res.data });
          }
        }
      }); 
    }
  };
  const requestOfferWithoutInsuranceId = async (startTime, additionalInsured, coverages, autoRenew, insuranceDuration, vehicleRequests) =>{
    return await awaitWithLoading(
        coveragesApi.requestOffer({
          start_time: startTime,
          additional_insured: additionalInsured,
          coverages: coverages,
          auto_renew: autoRenew,
          insurance_duration: insuranceDuration,
          vehicle_requests: vehicleRequests,
        }),
      );
  }

  const loadPlans = async (isUpdate?) => {
     if(insuranceId == undefined) {
      let res = await awaitWithLoading(coveragesApi.loadPlans(state.offerCart.period.plan)) 
      if (res.ok) {
        dispatch({ type: 'LOAD_PLANS', data: res.parsedData.plans });
      } else {
        // EventEmitterService.dispatch(EventKey.ShowError, res)
      }
  
      // Assume no defaults, hence setting default values by picking first plan
      if (!state.offerCart.aircraft_damage_liability || isUpdate) {
        if (res.data && res.data.plans && res.data.plans.length > 0) {
          let defaultPlan = res.data.plans[0];
          if (
            defaultPlan.vehicle_coverage_prices &&
            Object.keys(defaultPlan.vehicle_coverage_prices).length > 0
          ) {
            let defaultOptions =
              defaultPlan.vehicle_coverage_prices[
                Object.keys(defaultPlan.vehicle_coverage_prices)[0]
              ];
            let defaultValues = {};
            if (defaultOptions.coverage_prices) {
              Object.keys(defaultOptions.coverage_prices).map((optionName) => {
                defaultValues[optionName] =
                  defaultOptions.coverage_prices[optionName].coverage_option_id;
              });
            }
            dispatch({
              type: 'CART_CHANGED',
              data: { ...state.offerCart, ...defaultValues },
            });
          }
        }
       }
    }
  };

  const loadFormOptions = async () => {
    let res = await awaitWithLoading(coveragesApi.loadOptions());
    if (res.ok) {
      let parsedOptions = {};
      Object.keys(res.data).map((name) => {
        parsedOptions[name] = res.data[name].map((item) => {
          if (item.breakdown && item.breakdown.length > 1)
            return {
              labels: item.breakdown.map((k) => k.label),
              values: item.breakdown.map((k) => k.sublabel),
              value: item.value,
              tooltip: item.tooltip,
            };
          else if (item.breakdown)
            return {
              label: item.breakdown[0].sublabel,
              value: item.value,
              tooltip: item.tooltip,
            };
          else {
            return {
              label: item.subtitle,
              value: item.value,
              tooltip: item.tooltip,
            };
          }
        });
      });

      dispatch({ type: 'OPTIONS_LOADED', data: parsedOptions });
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res);
    }
  };

  const updateCartWithInsurance = (insurance) => {
      let policyCoverages = insurance.policyCoverages
      let offerCart = {
        additional_insured: insurance.additionalInsuredName,
        aircraft_damage_liability: policyCoverages.aircraftDamageLiability.coverageOptionId,
        bodily_injury_liability: policyCoverages.bodilyInjuryLiability.coverageOptionId,
        medical_payments: policyCoverages.medicalPayments.coverageOptionId,
        period:{
          endDate: insurance.validUntilUtc,
          plan: billingPeriod(insurance.insurancePeriodDescription),
          startDate: insurance.validFromUtc
        },
        autoRenew: insurance.autoRenew
      }
  
      dispatch({type: 'CART_CHANGED', data: offerCart})
  }

  useEffect(() => {
    if(sessionContext.user.userInsuredPersons.length > 0 && SessionService.isLoggedIn()) {
      Promise.all([loadPlans(), loadFormOptions()])
    }

    return function cleanup() {
      //TODO: Deleting the local storage only for broker (?)
      if (SessionService.isBroker()) StorageService.removeItem(OFFERS_HISTORY_KEY);
    };
  }, [sessionContext.user]);

  useEffect(() => {
    let id2 = EventEmitterService.subscribe(EventKey.USER_LOGGED_IN, () => {
        Promise.all([loadPlans(), loadFormOptions()])
    });

    let id3 = EventEmitterService.subscribe(EventKey.FLOW_PROFILE_FINISH, () => {
      // In case State has changed so offers will reflect taxes
      //requestOffer();  
    });

    let id5 = EventEmitterService.subscribe(EventKey.FLOW_PILOT_FINIISH, () => {
      // In case State has changed so offers will reflect taxes
      requestOffer();
    });

    let id4 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
      dispatch({ type: 'RESET' });
    });

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.USER_LOGGED_IN, id2);
      EventEmitterService.unsubscribe(EventKey.FLOW_PROFILE_FINISH, id3);
      EventEmitterService.unsubscribe(EventKey.FLOW_PILOT_FINIISH, id5);
      EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id4);
    };
  }, [state]);

  useEffect(() => {
    if (state != defaultState && SessionService.isLoggedIn()) {
      requestOffer(); 
      switch(state.offerCart.period.plan){
        case PlanEnum.Day:
          dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: (state.weeklyPriceBreakdown.price - state.dailyPriceBreakdown.price).toFixed(2)});
          break;
        case PlanEnum.Week:
          dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: (state.monthlyPriceBreakdown.price - state.weeklyPriceBreakdown.price).toFixed(2)});
          break;
      }
    }
  }, [state.offerCart.medical_payments,state.offerCart.aircraft_damage_liability,state.offerCart.bodily_injury_liability,state.offerCart.period.startDate,state.offerCart.autoRenew]);

  useEffect(() => {
    switch(state.offerCart.period.plan){
      case PlanEnum.Day:
        dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: state.dailyPriceBreakdown });
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: (state.weeklyPriceBreakdown.price - state.dailyPriceBreakdown.price).toFixed(2)});
        break;
      case PlanEnum.Week:
        dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: state.weeklyPriceBreakdown });
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: (state.monthlyPriceBreakdown.price - state.weeklyPriceBreakdown.price).toFixed(2)});
        break;
      case PlanEnum.Month:
        dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: state.monthlyPriceBreakdown });
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: 0 });
        break;
      case PlanEnum.Year:
        dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: state.yearlyPriceBreakdown });
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: 0 }); 
        break;
    }
  },[state.offerCart.period.plan ])

  useEffect(()=>{
    switch(state.offerCart.period.plan){
      case PlanEnum.Day:
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: (state.weeklyPriceBreakdown.price - state.dailyPriceBreakdown.price).toFixed(2)});
        break;
      case PlanEnum.Week:
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: (state.monthlyPriceBreakdown.price - state.weeklyPriceBreakdown.price).toFixed(2)});
        break;
      case PlanEnum.Month:
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: 0 });
        break;
      case PlanEnum.Year:
        dispatch({ type: 'CHANGE_UPGRADE_PRICE', data: 0 }); 
        break;
    }
  },[state.yearlyPriceBreakdown,state.monthlyPriceBreakdown,state.weeklyPriceBreakdown,state.dailyPriceBreakdown])


  useEffect(() => {
    let selectedAircraftDamageCoverage = state.options.aircraft_damage_liability.find(obj => obj.value === state.offerCart.aircraft_damage_liability)
    let selectedLiabilityCoverage = state.options.bodily_injury_liability.find(obj => obj.value === state.offerCart.bodily_injury_liability)
    let selectedMedicalPaymentCoverage = state.options.medical_payments.find(obj => obj.value === state.offerCart.medical_payments)
    if(SessionService.isLoggedIn() && insuranceId == undefined && !(selectedAircraftDamageCoverage && selectedLiabilityCoverage && selectedMedicalPaymentCoverage)){
       loadPlans(true)
    }
   
  },[state.options])

  useEffect(() => {
    // Reset Starting date after successfull purchase
    let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, () => {
      changeStartingDate(new Date());
    });

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id);
    };
  }, []);

  const changeStartingDate = (startingDate: Date) => {
    dispatch({ type: 'CHANGE_STARTING_DATE', startingDate: startingDate });
  };

  const loadQuote = (quote) => {
    dispatch({ type: 'LOAD_QUOTE', data: quote });
  };

  const flipSpecialPrice = () => {
    if (state.specialPrice.enabled == 'yes')
      dispatch({
        type: OFFERS_ACTIONS.SET_SPECIAL_PRICE,
        data: {
          enabled: 'no',
          custom_premium: state.specialPrice.custom_premium,
          custom_premium_token: state.specialPrice.custom_premium_token,
        },
      });
    else
      dispatch({
        type: OFFERS_ACTIONS.SET_SPECIAL_PRICE,
        data: {
          enabled: 'yes',
          custom_premium: state.specialPrice.custom_premium,
          custom_premium_token: state.specialPrice.custom_premium_token,
        },
      });
  };

  const setSpecialPrice = (premium, premiumToken) => {
    dispatch({
      type: OFFERS_ACTIONS.SET_SPECIAL_PRICE,
      data: {
        enabled: 'yes',
        custom_premium: premium,
        custom_premium_token: premiumToken,
      },
    });
  };

  if (state.planDurationPerState == null) {
    state.planDurationPerState = [];
    UserApi.getPlanDurationPerState().then((res) => {
      dispatch(
        new Action(
          OFFERS_ACTIONS.UPDATE_PLAN_DURATION_PER_STATE,
          res.data.plans_duration_per_state,
        ),
      );
    });
  }

  useEffect(() => {
    let id = EventEmitterService.subscribe(EventKey.USER_STATE_CHANGED, (userState) => {
      let newDefaultPlan = 'Year';
      let currentDisabledPlans = [];
      if (userState && state.planDurationPerState[userState]) {
        currentDisabledPlans = Object.keys(state.planDurationPerState[userState])
          .filter((k) => !state.planDurationPerState[userState][k])
          .map((k) => k[0].toUpperCase() + k.slice(1));
        newDefaultPlan = state.allPlans.filter((p) => currentDisabledPlans.indexOf(p) === -1)[0];
      }
      dispatch(new Action(OFFERS_ACTIONS.UPDATE_DISABLED_PLANS, currentDisabledPlans));
      dispatch(
        new Action('CART_CHANGED', {
          ...state.offerCart,
          period: { ...state.offerCart.period, plan: newDefaultPlan },
        }),
      );
    });

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.USER_STATE_CHANGED, id);
    };

  }, [sessionContext.user.state]);

  const choosePlan = (plan) => {
    let configuration = {
      vehicle_requests: {},
    };

    plan.coverages
      .filter((coverage) => coverage.type == 'policy')
      .forEach((coverage) => {
        configuration[coverage.name] = coverage.value;
      });

    let vehicleValues = {};
    plan.coverages
      .filter((coverage) => coverage.type == 'vehicle')
      .forEach((coverage) => {
        vehicleValues[coverage.name] = coverage.value;
      });

    sessionContext.user.userVehicles.forEach((vehicle) => {
      configuration.vehicle_requests[vehicle.id] = {
        coverages: { ...vehicleValues },
      };
    });

    //TODO: remove this line
    coveragesApi.hackSavePlan(plan.id);
    dispatch({ type: 'CART_CHANGED', data: configuration });
  };

  const purchaseOffer = async () => {
    return await coveragesApi.purchaseOffer(state.offerId, state.offerCart.autoRenew);
  };

  return (
    <BuyingInsuranceContextStore.Provider
      value={{
        ...state,
        dispatch,
        choosePlan,
        setSpecialPrice,
        flipSpecialPrice,
        purchaseOffer,
        changeStartingDate,
        loadQuote,
        updateCartWithInsurance
      }}
    >
      {props.children}
    </BuyingInsuranceContextStore.Provider>
  );
};

export default BuyingInsuranceContext;
